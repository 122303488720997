/* FAQ.css */
@import '~font-awesome/css/font-awesome.min.css';

.faq-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: white;
  }
  
  .faq-columns {
    display: flex;
    width: 80%;
    max-width: 800px;
  }
  
  .faq-column {
    flex: 1;
    padding: 20px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: none; /* Remove the shadow */
  }
  
  .faq-item {
    background-color: #fff;
    margin-bottom: 20px;
    border: none; /* Remove the border */
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between; /* Align question and icon horizontally */
    cursor: pointer;
    font-weight: bold;
    padding: 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: background-color 0.2s ease-in-out;
  }
  
  .faq-question:hover {
    background-color: #e0e0e0;
  }
  
  .faq-question.active {
    background-color: #4caf50;
    color: #fff;
    border-color: #4caf50;
  }
  
  .faq-answer {
    padding: 10px;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 5px 5px;
    background-color: #fff;
  }
  