.testimonials-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; 
}

.testimonial {
  max-width: 350px; 
  padding: 20px;
  background-color: #f5f5f5; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  text-align: center;
  position: relative; 
  display: flex; 
  flex-direction: row; 
  align-items: center; 
}

.testimonial:before {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 10px 10px 0;
  border-color: #f5f5f5 transparent transparent transparent;
  top: -10px;
  left: 50%;
  transform: translateX(-50%); 
}

.testimonial-photo-container {
  width: 80px; 
  height: auto; 
  margin-right: 10px; 
  overflow: hidden; 
  border-radius: 50%; 
  flex-shrink: 0; 
}

.testimonial-photo {
  width: 100%; 
  height: 100%; 
  object-fit: cover; 
  border-radius: 50%; 
}

.testimonial-content {
  flex-grow: 1; 
  text-align: left; 
}

.testimonial-author {
  margin-bottom: 5px; 
}

.testimonial-name {
  font-weight: bold;
}

.testimonial-designation {
  color: #777; 
  font-size: 14px;
}

.testimonial-text {
  font-size: 16px;
  margin-top: 15px; 
  padding: 10px; 
  background-color: #f5f5f5; 
  border-radius: 5px;
  position: relative; 
}


